<template>
  <div class="tabs is-centered">
    <ul>
      <router-link
        v-for="route in routes"
        :key="route.display"
        active-class="is-active"
        :exact="true"
        tag="li"
        :to="{ name: route.name }"
      >
        <router-link :to="{ name: route.name }">
          {{ route.display }}
        </router-link>
      </router-link>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    paymentProvider: String,
  },
  computed: {
    paymentProviderName() {
      switch (this.paymentProvider) {
        case 'CS':
          return 'Cardstream';
        case 'WPM':
          return 'WPM';
        case 'VYNE':
          return 'Vyne';
        case 'VYNE_PARTNER':
          return 'Vyne Partner';
        case 'WU':
          return 'Western Union';
        default:
          return 'payment provider';
      }
    },
  },
  created() {
    const requestSent = {
      display: `Request sent to ${this.paymentProviderName}`,
      name: 'adaptedRequest',
    };

    this.$data.routes.push(requestSent);
  },
  data() {
    return {
      routes: [
        { display: 'Overview', name: 'paymentPlanOverview' },
        { display: 'History', name: 'paymentPlanHistory' },

      ],
    };
  },
};
</script>
